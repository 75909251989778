* {
  box-sizing: border-box;
}

body, html {
  height: 100vh;
  font-size: 62.5%; /* Now 10px = 1rem! */
  margin: 0;
  padding: 0;
}

body {
  background-color: #ecf0f1;
  color: #333;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2 {
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

h1 {
  font-size: 12rem;
}

h2 {
  font-size: 3rem;
}

img {
  max-width: 100%;
}
