.countdown-button {
  display: block;
  height: 270px;
  width: 270px;
  margin: 0 auto;
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  visibility: visible;
}

.countdown-button:hover {
  cursor: pointer;
  height: 280px;
  width: 280px;
}

.countdown-button.animate {
  animation: spin 0.6s linear infinite;
}

.countdown-button.fade {
  margin: auto;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

