
main {
  width: 100vw;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.menu-button {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 50%;
  box-shadow: 0 2px 1px #ccc;
  display: block;
  height: 72px;
  padding: 5px;
  position: absolute;
  right: 30px;
  text-decoration: none;
  top: 30px;
  width: 72px;
}

.menu-button:hover {
  box-shadow: 0 2px 3px #ccc;
}

.main-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.raised-hand-emoji {
  font-size: 4rem;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.hidden {
  display: none;
  visibility: hidden;
}
